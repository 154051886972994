video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background: url('/public/images/img-1.jpg') center center/cover no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  width: 100%;
  height: 60vh;
}

.heros__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.heros__wrapper {
  position: relative;
  margin: 50px 0 50px;
}

.heros__items {
  margin-bottom: 24px;
}

.heros__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.heros__item__pic-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.heros__item__img {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.heros__items > h1 {
  color: #00ABFF;
  font-size: 36px;
  margin-top: -100px;
}

.heros__items > h2 {
  color: #00ABFF;
  font-size: 24px;
  margin-top: 10px;
}

.heros__items > h3 {
  color: #00ABFF;
  font-size: 18px;
  margin-top: 10px;
}

.heros__items > p {
  font-size: 16px;
  margin-top: 10px;
  color: #000000;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.heros__image {
  width: 100%;
}

.hero-container > h1 {
  color: #ffffff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > h2 {
  color: #ffffff;
  font-size: 80px;
  margin-top: 10px;
}

.hero-container > h3 {
  color: #ffffff;
  font-size: 60px;
  margin-top: 10px;
}

.hero-container > p {
  margin-top: 8px;
  color: #ffffff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}


@media screen and (max-width: 960px) {
  
  .heros__container > h1 {
    font-size: 36px;
    margin-top: -100px;
  }

  .heros__container > p {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {

  .heros__container > h1 {
    font-size: 36px;
    margin-top: -100px;
  }

  .heros__container > p {
    font-size: 16px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
