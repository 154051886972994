body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #00ABFF;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.products,
.services,
.special,
.contact-us,
.terms-conditions,
.warehouse-stock,
.skf,
.nachi {
  display: flex;
  height: 60vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.products {
  background-image: url('/images/img-4.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.services {
  background-image: url('/images/img-3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.special {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.contact-us {
  background-image: url('/images/img-7.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.terms-conditions {
  background-image: url('/images/img-6.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.warehouse-stock {
  background-image: url('/images/img-10.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.skf {
  background-image: url('/images/img-13.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.nachi {
  background-image: url('/images/img-19.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 960px) {

.home,
.products,
.services,
.special,
.contact-us,
.terms-conditions,
.warehouse-stock,
.skf,
.nachi {
  background-size: cover;
  max-height: 25vh;
}
}


:root {
  --primary: #ffffff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #ffffff;
  background-color: var(--primary);
  color: #00ABFF;
  border: 1px solid #ffffff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #ffffff;
  padding: 8px 20px;
  border: 1px solid #ffffff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #ffffff;
  color: #00ABFF;
  transition: 250ms;
}

.navbar {
  background: linear-gradient(90deg, #00ABFF 0%, #00ABFF 100%);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: relative;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  max-width: 1920px;
}

.navbar-logo {
  color: #ffffff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display:inline-flex;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: flex-end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #ffffff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #ffffff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #ffffff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    -webkit-transform: translate(0%, 20%);
            transform: translate(0%, 20%);
    font-size: 1.2rem;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #ffffff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #ffffff;
    padding: 14px 20px;
    border: 1px solid #ffffff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #ffffff;
    color: #242424;
    transition: 250ms;
  }
}

.footer-container {
  background-color: #00ABFF;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #ffffff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #ffffff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  text-align: center;
  max-width: 320px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-top: -16px;
  margin-bottom: -16px;
}

.footer-link-items > h2 {
  color: #ffffff;
}

.footer-link-items a {
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #ffffff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #ffffff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #ffffff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background: url('/public/images/img-1.jpg') center center/cover no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  width: 100%;
  height: 60vh;
}

.heros__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.heros__wrapper {
  position: relative;
  margin: 50px 0 50px;
}

.heros__items {
  margin-bottom: 24px;
}

.heros__item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.heros__item__pic-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.heros__item__img {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.heros__items > h1 {
  color: #00ABFF;
  font-size: 36px;
  margin-top: -100px;
}

.heros__items > h2 {
  color: #00ABFF;
  font-size: 24px;
  margin-top: 10px;
}

.heros__items > h3 {
  color: #00ABFF;
  font-size: 18px;
  margin-top: 10px;
}

.heros__items > p {
  font-size: 16px;
  margin-top: 10px;
  color: #000000;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.heros__image {
  width: 100%;
}

.hero-container > h1 {
  color: #ffffff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > h2 {
  color: #ffffff;
  font-size: 80px;
  margin-top: 10px;
}

.hero-container > h3 {
  color: #ffffff;
  font-size: 60px;
  margin-top: 10px;
}

.hero-container > p {
  margin-top: 8px;
  color: #ffffff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}


@media screen and (max-width: 960px) {
  
  .heros__container > h1 {
    font-size: 36px;
    margin-top: -100px;
  }

  .heros__container > p {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {

  .heros__container > h1 {
    font-size: 36px;
    margin-top: -100px;
  }

  .heros__container > p {
    font-size: 16px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

.cards {
  padding: 0 1rem 0 1rem;
  background: #ffffff;
}

h2 {
  text-align: center;
  color: #00ABFF;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.fade-img {
  -webkit-animation-name: fade-img;
          animation-name: fade-img;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  background-color: #00ABFF;
  box-sizing: border-box;
}

.cards__item__img {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #00ABFF;
  font-size: 24px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

.product {
  padding: 4rem;
  background: #ffffff;
}

h2 {
  text-align: center;
  color: #00ABFF;
  padding: 20px 30px 30px;
}

.products__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.products__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.products__items {
  margin-bottom: 24px;
}

.products__item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.products__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.products__item__pic-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.fade-img {
  -webkit-animation-name: fade-img;
          animation-name: fade-img;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.products__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  background-color: #00ABFF;
  box-sizing: border-box;
}

.products__item__img {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.products__item__img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.products__item__info {
  padding: 20px 30px 30px;
}

.products__item__text {
  color: #00ABFF;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .products__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .products__item {
    margin-bottom: 2rem;
  }
}

table, th, td {
    width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
    padding: 10px;
    border-bottom: 2px solid #00ABFF;
  }

  th {
    text-align: left;
    color: white;
    background-color: #00ABFF;
  }

  tr:hover {
    color: white;
    background-color: #00ABFF;
  }

  @media screen and (max-width: 960px) {
  
    p {
      font-size: 12px;
    }

    table, th, td {
      width: 100%;
      overflow-x: auto;
      border-collapse: collapse;
      padding: 4px;
      border-bottom: 2px solid #00ABFF;
    }
  }
  
  @media screen and (max-width: 768px) {
  
    p {
      font-size: 6px;
    }

    table, th, td {
      width: 100%;
      overflow-x: auto;
      border-collapse: collapse;
      padding: 2px;
      border-bottom: 2px solid #00ABFF;
    }
  }

  @media screen and (max-width: 320px) {
  
    p {
      font-size: 6px;
    }

    table, th, td {
      width: 60%;
      overflow-x: auto;
      border-collapse: collapse;
      padding: 1px;
      border-bottom: 2px solid #00ABFF;
    }
  }
