table, th, td {
    width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
    padding: 10px;
    border-bottom: 2px solid #00ABFF;
  }

  th {
    text-align: left;
    color: white;
    background-color: #00ABFF;
  }

  tr:hover {
    color: white;
    background-color: #00ABFF;
  }

  @media screen and (max-width: 960px) {
  
    p {
      font-size: 12px;
    }

    table, th, td {
      width: 100%;
      overflow-x: auto;
      border-collapse: collapse;
      padding: 4px;
      border-bottom: 2px solid #00ABFF;
    }
  }
  
  @media screen and (max-width: 768px) {
  
    p {
      font-size: 6px;
    }

    table, th, td {
      width: 100%;
      overflow-x: auto;
      border-collapse: collapse;
      padding: 2px;
      border-bottom: 2px solid #00ABFF;
    }
  }

  @media screen and (max-width: 320px) {
  
    p {
      font-size: 6px;
    }

    table, th, td {
      width: 60%;
      overflow-x: auto;
      border-collapse: collapse;
      padding: 1px;
      border-bottom: 2px solid #00ABFF;
    }
  }