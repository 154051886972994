.App {
  text-align: center;
}

.App-header {
  background-color: #00ABFF;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.products,
.services,
.special,
.contact-us,
.terms-conditions,
.warehouse-stock,
.skf,
.nachi {
  display: flex;
  height: 60vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.products {
  background-image: url('/images/img-4.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.services {
  background-image: url('/images/img-3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.special {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.contact-us {
  background-image: url('/images/img-7.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.terms-conditions {
  background-image: url('/images/img-6.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.warehouse-stock {
  background-image: url('/images/img-10.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.skf {
  background-image: url('/images/img-13.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.nachi {
  background-image: url('/images/img-19.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 3rem;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 960px) {

.home,
.products,
.services,
.special,
.contact-us,
.terms-conditions,
.warehouse-stock,
.skf,
.nachi {
  background-size: cover;
  max-height: 25vh;
}
}

